export const constants = {
    
    api:{
        parceiro: 'SUCESSO',
    },

    texts:{
        nomeEmpresa: 'SUCESSO',
    },

    links:{
        linkPedirChip: null,
        linkApp: 'https://infiniti.app/provedores/aplicativos/infinitiapp',
        linkWpp: 'https://api.whatsapp.com/send/?phone=%2B557330274293&text&type=phone_number&app_absent=0',
        linkMapaCobertura: 'https://tim.img.com.br/mapa-cobertura/',
        linkTenhaSuaOperadora: null,
        // linkTenhaSuaOperadora: 'https://api.whatsapp.com/send?phone=5561920040101&text=quero%20saber%20mais', // INFINITI Apenas
        linkRastrearMeuPedido: 'https://tracking.totalexpress.com.br/',
        linkPoliticaDePrivacidade: 'https://privacidade.operadora.app.br/#/sucesso',
        linkInstagram: null,
    },
}